{
  "active": "Active",
  "add": "Add",
  "add-countries": "Add countries",
  "add-delivery-area": "Add delivery area",
  "add-holiday": "Add holiday",
  "add-widget": "Add widget",
  "additional-delivery-delay": "Additional delivery delay",
  "additional-delivery-delay-info": "Added to the delivery delay in general settings.",
  "additional-shipping-delay": "Additional shipping delay",
  "additional-shipping-delay-info": "Added to the shipping delay in general settings.",
  "all-regions": "All regions",
  "back": "Back",
  "billing": "Billing",
  "billing-faq-1": "How do I pay?",
  "billing-faq-1-answer": "All payments are processed through your Shopify account.",
  "billing-faq-2": "How do I cancel my subscription?",
  "billing-faq-2-answer": "You can cancel your subscription at any time on this page.",
  "billing-feature-1": "Unlimited monthly visitors and orders",
  "billing-feature-2": "Access to all features and future updates",
  "billing-feature-3": "Technical support via live chat and email",
  "billing-subtitle": "Select a plan and manage your subscription.",
  "billing-whitelisted": "Your account has been whitelisted by an admin. You can use Delm for free.",
  "can-deliver": "Deliveries can be delivered",
  "can-ship": "Deliveries can be shipped",
  "cancel": "Cancel",
  "cancel-subscription": "Cancel subscription",
  "cancel-subscription-info": "Delm will be disabled after cancellation.",
  "cancel-subscription-title": "Really cancel?",
  "change-order-deadline": "Change order deadline for same day shipping",
  "changes-discarded": "Changes discarded",
  "close": "Close",
  "common-questions": "Common questions",
  "content": "Content",
  "continue": "Continue",
  "continue-to-widgets": "Continue to widgets",
  "copied": "Copied",
  "copied-to-clipboard": "Copied to clipboard",
  "copy": "Copy",
  "count-languages": "No languages | 1 language | {count} languages",
  "count-more-countries": "+{count} more countries",
  "count-placeholders": "1 placeholder | {count} placeholders",
  "count-selected-regions": "{selected} of {total} regions",
  "countdown-count-placeholder": "Displays the remaining count of the time unit",
  "countdown-info": "Settings for displaying the real-time countdown in the order deadline.",
  "countdown-placeholder": "Displays a countdown in real-time",
  "countries": "Countries",
  "country-already-selected": "Selected in another delivery area.",
  "country-code-emoji-placeholder": "Display visitor country code as an emoji flag. Emoji flags are supported on all major platforms except Windows.",
  "country-code-lower-placeholder": "Display visitor ISO 3166 country code in lowercase",
  "country-code-upper-placeholder": "Display visitor ISO 3166 country code in uppercase",
  "country-name-placeholder": "Displays the visitor country name",
  "css-rules": "CSS rules for all templates",
  "css-selector": "CSS selector",
  "css-selector-is-required": "CSS selector is required.",
  "current-plan": "Your current plan",
  "custom-holidays": "Custom holidays",
  "custom-holidays-info": "Prioritized when they overlap with public holidays.",
  "customize": "Customize",
  "customize-delivery-area": "Customize delivery area",
  "customize-delivery-areas": "Customize delivery areas",
  "customize-delivery-areas-info": "Change transit times or disable delivery areas.",
  "customize-product": "Customize product",
  "date-D-placeholder": "The day of the month",
  "date-DD-placeholder": "The day of the month, 2-digits",
  "date-Do-placeholder": "The day of the month with ordinal",
  "date-M-placeholder": "The month, beginning at 1",
  "date-MM-placeholder": "The month, 2-digits",
  "date-MMM-placeholder": "The abbreviated month name",
  "date-MMM-placeholder-example": "Jan-Dec",
  "date-MMMM-placeholder": "The full month name",
  "date-MMMM-placeholder-example": "January-December",
  "date-YY-placeholder": "Two-digit year",
  "date-YYYY-placeholder": "Four-digit year",
  "date-dd-placeholder": "The first 2 characters of the weekday name",
  "date-dd-placeholder-example": "Su-Sa",
  "date-ddd-placeholder": "The first 3 characters of the weekday name",
  "date-ddd-placeholder-example": "Sun-Sat",
  "date-dddd-placeholder": "The full weekday name",
  "date-dddd-placeholder-example": "Sunday-Saturday",
  "date-formats": "Date formats",
  "date-formats-info": "The date formats depend on the estimated delivery date or shipping date. For example, if you have configured a transit time range of 2-3 days, the date range format will be used for display.",
  "date-range": "Date range",
  "date-range-earliest": "Date range earliest",
  "date-range-latest": "Date range latest",
  "day": "day",
  "day-singular": "Day (singular)",
  "days": "days",
  "days-plural": "Days (plural)",
  "days-range": "Days as range",
  "default": "Default",
  "default-language": "Default language",
  "default-select-placeholder": "Select option",
  "delete": "Delete",
  "delete-delivery-area": "Delete delivery area",
  "delete-delivery-area-info": "Are you sure you want to delete this delivery area?",
  "delete-widget": "Delete widget",
  "delete-widget-confirm": "Are you sure you want to delete this widget?",
  "delete-widget-info": "Deleted widgets cannot be restored.",
  "delivery-area": "Delivery area",
  "delivery-area-countries-info": "Specify countries that are part of this delivery area.",
  "delivery-area-settings-info": "Specify transit time and delivery days for this delivery area.",
  "delivery-areas": "Delivery areas",
  "delivery-areas-empty-title": "Start by creating your first delivery area",
  "delivery-areas-subtitle": "Configure delivery times based on customer location.",
  "delivery-date": "Delivery date",
  "delivery-date-info": "The date on which a delivery is delivered to the customer.",
  "delivery-date-placeholder": "Displays the estimated delivery date",
  "delivery-days": "Delivery days",
  "delivery-days-info": "On which days can deliveries be delivered?",
  "delivery-delay": "Delivery delay",
  "delivery-delay-info": "Postpone delivery date for all products.",
  "delivery-messages": "Delivery messages",
  "delivery-messages-banner-info": "Disable delivery messages and use widgets instead.",
  "delivery-messages-deprecated": "This feature is deprecated and will be removed soon. We recommend all merchants to disable this feature and migrate to widgets.",
  "delivery-messages-disabled": "Delivery messages disabled",
  "delivery-messages-enabled": "Delivery messages enabled",
  "delivery-messages-languages-info": "Delivery messages are automatically displayed in the store language.",
  "delivery-messages-next-day-template": "Template for next day delivery",
  "delivery-messages-next-day-template-info": "Used when delivery date is tomorrow.",
  "delivery-messages-range-template": "Template for delivery date as date range",
  "delivery-messages-range-template-info": "Used when transit time is configured as a range, for example 2-3 days.",
  "delivery-messages-single-template": "Template for single delivery date",
  "delivery-messages-single-template-info": "Used when delivery date is not tomorrow.",
  "delivery-messages-subtitle": "Configure how estimated delivery dates are displayed.",
  "delivery-time": "Delivery time",
  "delivery-time-count-max-placeholder": "The maximum delivery time in days",
  "delivery-time-count-min-placeholder": "The minimum delivery time in days",
  "delivery-time-count-placeholder": "The delivery time in days",
  "delivery-time-info": "Settings for displaying the delivery time.",
  "delivery-time-placeholder": "Displays the delivery time in days",
  "delivery-to": "Delivery to",
  "delm-is-disabled": "Delm is disabled.",
  "delm-is-enabled": "Delm is enabled.",
  "delm-is-unsubscribed": "Delm is disabled, go to billing and select a plan.",
  "deprecated-feature": "Deprecated feature",
  "description": "Description",
  "disable-delivery-area": "Disable delivery area",
  "disable-delivery-messages": "Disable delivery messages",
  "disable-delm": "Disable Delm",
  "disable-oversold-products": "Disable Delm for oversold products",
  "disable-script-tag-api": "Disable Shopify ScriptTag API",
  "disable-script-tag-api-info": "Only enable this option if you want to manually include our JavaScript file.",
  "disable-unavailable-products": "Disable Delm for unavailable products",
  "disabled": "Disabled",
  "discard": "Discard",
  "discard-dialog-info": "You'll delete any edits you made since you last saved.",
  "discard-dialog-title": "Discard unsaved changes",
  "display-delivery-message": "Display widgets / delivery message",
  "docs": "Documentation",
  "docs-subtitle": "Learn more about Delm.",
  "dont-set-as-default": "Don't set as default",
  "draft": "Draft",
  "duplicate": "Duplicate",
  "earliest-placeholder": "The earliest date",
  "edit-delivery-area": "Edit delivery area",
  "edit-regions": "Edit regions",
  "enable-all-products": "Enable Delm for all products",
  "enable-delivery-messages": "Enable delivery messages",
  "enable-delm": "Enable Delm",
  "enabled": "Enabled",
  "enter-hours": "Enter hours",
  "enter-shopify-domain": "Enter Shopify domain",
  "error": "An unexpected error has occurred, please try again or contact us",
  "example": "Example",
  "general-settings": "General settings",
  "general-settings-products-info": "General settings for products.",
  "general-settings-subtitle": "Start here to configure Delm.",
  "holiday": "Holiday",
  "holidays": "Holidays",
  "holidays-subtitle": "Create holidays and check upcoming public holidays.",
  "home-subtitle": "Boost sales with clear delivery details: Display estimated delivery dates, delivery times and more.",
  "home-title": "Welcome to Delm",
  "hour-singular": "Hour (singular)",
  "hours": "Hours",
  "hours-plural": "Hours (plural)",
  "imprint": "Imprint",
  "info-created": "{name} created",
  "info-deleted": "{name} deleted",
  "info-delm-disabled": "Delm disabled",
  "info-delm-enabled": "Delm enabled",
  "info-demoted": "{name} is no longer set as default",
  "info-duplicated": "{name} duplicated",
  "info-promoted": "{name} is now set as default",
  "info-saved": "{name} saved",
  "internal-name": "Internal name",
  "internal-name-info": "Not visible to customers.",
  "language-names": {
    "ar": "Arabic",
    "bg": "Bulgarian",
    "cs": "Czech",
    "da": "Danish",
    "de": "German",
    "el": "Greek",
    "en": "English",
    "es": "Spanish",
    "fi": "Finnish",
    "fr": "French",
    "hr": "Croatian",
    "hu": "Hungarian",
    "it": "Italian",
    "ja": "Japanese",
    "ko": "Korean",
    "lt": "Lithuanian",
    "lv": "Latvian",
    "nb": "Norwegian (Bokmål)",
    "nl": "Dutch",
    "pl": "Polish",
    "pt": "Portuguese",
    "ro": "Romanian",
    "sk": "Slovak",
    "sl": "Slovenian",
    "sv": "Swedish",
    "zh": "Chinese"
  },
  "languages": "Languages",
  "last-words-connector": "Last words connector",
  "latest-placeholder": "The latest date",
  "leave-page": "Leave page",
  "leave-page-info": "If you leave this page, unsaved changes will be lost.",
  "link-docs": "https://delm.io/documentation/",
  "link-docs-delivery-areas": "https://delm.io/documentation/delivery-areas.html",
  "link-docs-delivery-messages": "https://delm.io/documentation/delivery-messages.html",
  "link-docs-disable-script-tag-api": "https://delm.io/documentation/delivery-messages.hdocs-tml#disable-shopify-scripttag-api",
  "link-docs-general-settings": "https://delm.io/documentation/general-settings.html",
  "link-docs-holidays": "https://delm.io/documentation/holidays.html",
  "link-docs-products": "https://delm.io/documentation/products.html",
  "link-docs-support": "https://delm.io/customer-service/",
  "link-docs-theme-integration": "https://delm.io/documentation/widgets.html#theme-integration",
  "link-docs-widgets": "https://delm.io/documentation/widgets.html",
  "link-imprint": "https://delm.io/imprint/",
  "link-privacy-policy": "https://delm.io/privacy-policy/",
  "link-terms-of-service": "https://delm.io/terms-of-service/",
  "login": "Log in",
  "login-subtitle": "Continue to Delm",
  "logout": "Logout",
  "logout-dialog-info": "{name} will be logged out.",
  "logout-dialog-title": "Really log out?",
  "main-content": "Main content",
  "manage-countries": "Manage countries",
  "manage-languages": "Manage languages",
  "merchant-working-days": "Shipping days and order deadlines",
  "merchant-working-days-info": "On what days can orders be shipped, and what is the order deadline for same-day shipping?",
  "minute-singular": "Minute (singular)",
  "minutes-plural": "Minutes (plural)",
  "month-names": [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  "more-settings": "More settings",
  "must-be-greater-than-min-transit-time": "Must be greater than minimum transit time.",
  "must-be-greater-than-zero": "Minimum transit time must be greater than 0.",
  "must-be-zero-or-greater": "Must be zero or greater.",
  "myshopify-example": "example.myshopify.com",
  "name": "Name",
  "name-is-required": "Name is required.",
  "national-holiday": "National holiday",
  "new-delivery-area": "New delivery area",
  "new-widget": "New widget",
  "next": "Next",
  "no": "No",
  "no-countries-added": "No countries added.",
  "no-countries-found": "No countries found.",
  "no-delivery-areas": "No delivery areas",
  "no-languages-added": "No languages added yet.",
  "no-products": "No products",
  "no-products-found": "No products found.",
  "no-products-info": "It may take a few minutes to sync products after installation.",
  "no-upcoming-holidays": "No upcoming holidays",
  "of": "of",
  "one-day-must-be-active": "At least one day must be active.",
  "open-guide": "Open guide",
  "order-deadline": "Order deadline",
  "order-deadline-content-info": "You can use HTML code to customize the appearance and layout of the content.",
  "order-deadline-info": "Settings for displaying the order deadline to increase urgency.",
  "order-deadline-placeholder": "Displays the order deadline text that contains a real-time countdown",
  "page-not-found": "Page not found",
  "page-not-found-subtitle": "Apparently a wrong address was entered.",
  "partner-friendly-info": "We are partner-friendly. You can use Delm without a subscription while the store is in development mode.",
  "password": "Password",
  "placeholder": "Placeholder",
  "placeholder-name": "{name} placeholder",
  "previous": "Previous",
  "price-per-month": "For {price}/month",
  "privacy-policy": "Privacy policy",
  "product": "Product",
  "product-settings-info": "Configure custom product settings.",
  "products": "Products",
  "products-subtitle": "Configure delivery times for individual products.",
  "public-holidays": "Public holidays",
  "public-holidays-info": "We ensure that delivery dates do not fall on holidays by checking holidays of the customer location.",
  "regions": "regions",
  "remaining-hours": "Remaining hours",
  "remove": "Remove",
  "remove-settings": "Remove settings",
  "require-customer-location-match": "Require customer location match",
  "require-customer-location-match-info": "Display estimated delivery dates only if the customer location matches an active delivery area. Transit time and delivery days are taken from the delivery area.",
  "save": "Save",
  "search-countries": "Search countries",
  "search-products": "Search products",
  "seconds": "Seconds",
  "select-date": "Select date",
  "select-language": "Select language",
  "select-plan": "Select a plan",
  "set-as-default": "Set as default",
  "settings": "Settings",
  "shipping-date": "Shipping date",
  "shipping-date-info": "The date on which a delivery is handed over to the shipping service provider.",
  "shipping-date-placeholder": "Displays the shipping date",
  "shipping-delay": "Shipping delay",
  "shipping-delay-info": "Postpone shipping date for all products.",
  "show-leading-zeros": "Show leading zeros",
  "show-more": "Show more",
  "show-seconds": "Show seconds",
  "show-seconds-info": "Show seconds if remaining time is less, otherwise hide. Set to zero to not display the seconds.",
  "show-zero-values": "Show zero values",
  "single-date": "Single date",
  "start-trial": "Start {days}-day free trial",
  "status": "Status",
  "subscribe": "Subscribe",
  "subscription-cancelled": "Subscription cancelled",
  "subscription-required-info": "An active subscription is required to enable Delm.",
  "support": "Support",
  "support-subtitle": "Contact us if you have any questions.",
  "terms-of-service": "Terms of service",
  "theme-integration": "Theme integration",
  "theme-integration-info": "Use the Delm widget app block or integrate the app block code into Liquid templates.",
  "timezone": "Base time zone for date calculations",
  "to": "to",
  "today-format": "Today",
  "today-placeholder": "Displays the current date in single date format",
  "tomorrow-format": "Tomorrow",
  "transit-days": "Transit days",
  "transit-days-info": "On what days can deliveries be transported? Blocked days are not counted towards the transit time.",
  "transit-time": "Transit time",
  "transit-time-info": "How long does it take for a delivery to reach the customer after it has been shipped?",
  "unlimited-plan": "Unlimited",
  "unsaved-changes": "Unsaved changes",
  "until": "until",
  "update": "Update",
  "use-default-value": "Use default value",
  "visibility": "Visibility",
  "visibility-info": "Show if time is less, otherwise hide. Set to zero to always show.",
  "weekday-names": [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ],
  "weekday-substring-end": "3",
  "widget": "Widget",
  "widget-add-languages": "Add languages",
  "widget-add-languages-info": "Widget content is edited per language.",
  "widget-content": "Widget content",
  "widget-content-info": "Enter the content you want to display in your widget. You can use HTML code to customize the appearance and layout of the content.",
  "widget-default-info": "The default widget is used when no other widget is specified.",
  "widget-id": "Widget ID",
  "widget-id-empty-info": "Save widget to view the ID.",
  "widget-id-info": "Unique permanent ID to load a specific widget.",
  "widget-name-example": "For example \"Product Page v1\"",
  "widget-position": "Position on product pages",
  "widget-positions": {
    "after": "After first element that matches CSS selector",
    "append": "Append to first element that matches CSS selector",
    "before": "Before first element that matches CSS selector",
    "prepend": "Prepend to first element that matches CSS selector"
  },
  "widget-status-info": "Draft widgets are only visible in the Shopify theme editor.",
  "widget-template": "Widget template",
  "widgets": "Widgets",
  "widgets-empty-title": "Start by creating your first widget",
  "widgets-subtitle": "Create widgets to display estimated delivery dates, delivery times and more. Integrate widgets via app block or Liquid code.",
  "words-connector": "Words connector",
  "yes": "Yes"
}
